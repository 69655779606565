.education-section {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    display: inline;

    h1 {
      margin-bottom: 10px;
    }
  
    h2 {
      font-size: 24px;
      margin-bottom: 20px;
    }
  
    .education-item {
      margin-bottom: 20px;
      padding: 20px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
      h3 {
        font-size: 20px;
        margin-bottom: 10px;
        margin-top: 0;
      }
  
      .sub-info {
        font-size: 14px;
        margin-bottom: 5px;
      }
  
      p {
        margin-bottom: 10px;
      }
    }
  }
  