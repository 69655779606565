h1 {
  font-size: 2.5rem;
  margin: 0;
}

h2 {
  font-size: 1.5rem;
  display: block;
  font-style: italic;
}

h3 {
  font-size: 1rem;
  display: block;
}

.contact {
  background-color: #fff;
  padding-bottom: 30px;
}

.contact-header {
  padding: 0;
  margin: 0;
}

.contact-info {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.contact-details {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 100px;
  margin-bottom: 20px;
}

.contact-item {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.contact-item:hover {
  background-color: rgb(214, 252, 240);
  transition: background-color 0.5s ease;
}

.contact-info p {
  margin: 0;
}

.contact-info a {
  color: #333; /* Text color */
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
  transition: text-decoration 3s;
}

.profile-picture-container {
  margin-right: 20px; 
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 15px;
}


/* Media query for mobile */
@media (max-width: 767px) {
  .contact-details {
    flex-direction: column; /* Stack items vertically */
  }

  .profile-picture-container {
    margin-right: 0; /* Remove margin on mobile */
  }
}