.navbar {
    background-color: #252525;
    font-family: 'Raleway', sans-serif;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .navbar-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
    }

    .logo-container {
        width: 50px;
        height: auto;
    }

    .logo-container img {
        max-width: 100%;
        height: auto;
    }

    .menu-toggle {
        display: none;
        color: #fff;
        font-size: 24px;
        cursor: pointer;
    }

    .navbar-links {
        list-style-type: none;
        margin: 0;
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;

        li {
            margin-right: 20px;
            a {
                color: #fff;
                font-size: 1.2rem;
                text-decoration: none;
                transition: color 0.3s ease;

                &:hover {
                    color: #ccc;
                }
            }
        }
    }

    @media (max-width: 767px) {
        .menu-toggle {
            display: block;
        }
    
        .navbar-links {
            display: flex;
            flex-direction: column;
            background-color: #222;
            padding: 0; /* Set padding to 0 */
            margin: 0; /* Set margin to 0 */
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            transition: max-height 0.5s ease;
            overflow: hidden;
            max-height: 0;

            &.active {
                max-height: 1000px;
            }
    
            li {
                margin: 0; /* Set margin to 0 */
                padding: 10px 0; /* Add padding to each list item */
    
                &:last-child {
                    margin-bottom: 0;
                }
    
                a {
                    font-size: 1.2rem;
                }
            }
        }
    }    
}
