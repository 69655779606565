.main-container {
  background-color: #f9f9f9; 
  max-width: 1200px; /* Adjust the maximum width as needed */
  margin: 0 auto; /* Center the container horizontally */
  padding: 0 75px; /* Add horizontal padding */
}

@media (max-width: 767px) {
  .main-container {
    background-color: #f9f9f9; 
    margin: 0 auto; /* Center the container horizontally */
    padding: 0 20px; /* Add horizontal padding */
  }
}