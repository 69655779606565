.skills-section {
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;

  h1 {
    margin-bottom: 10px;
  }

  h2 {
    color: rgb(214, 252, 240);
    font-size: 24px;
    margin-bottom: 20px;
  }

  ul {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style-type: none;
  }

  li {
    background-color: #fff;
    padding: 20px;
    margin: 0 10px 10px 0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  li:hover {
    background-color: rgb(214, 252, 240);
    transition: background-color 0.5s ease;
  }
}
